import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../FaceRadarComponents.module.scss'
import Form from '../../../components/form/Form'


class FeedbackBlock extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
  }
  

  render() {
    const { config } = this.props
    return (
      <div className={style.innerBlock} id='feedbackblock'>
        <h3
          dangerouslySetInnerHTML={{
            __html: config.heading,
          }}
        />
        <div className={style.feedbackForm}>
          <Form amoForm />
        </div>
      </div>
    )
  }
}

export default injectIntl(FeedbackBlock)
