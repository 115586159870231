import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../FaceRadarComponents.module.scss'
import Slider from 'react-slick'
class OpportunitiesBlock extends PureComponent {

  state = {
    activeBlock: 0,
    activeInfo: false,
  }
  static propTypes = {
    config: PropTypes.object,
    images: PropTypes.array,
  }

  /* nextBlock = () => {
    if (this.state.activeBlock + 1 === this.props.config.blocks.length) {
      this.setState(() => {
        return {activeBlock: 0};
      })
    } else {
      this.setState((state) => {
        return {activeBlock: state.activeBlock + 1};
      })
    }
  }

  prefBlock = () => {
    if (this.state.activeBlock -1 < 0) {
      this.setState(() => {
        return {activeBlock: this.props.config.blocks.length - 1};
      })
    } else {
      this.setState((state) => {
        return {activeBlock: state.activeBlock - 1};
      })
    }
  } */

  toggleInfo = () => {
    this.setState((state) => {
      return {activeInfo: !state.activeInfo};
    })
  }
  

  render() {
    const { config, images } = this.props
    let pictures = {};
    images.map(x => pictures[x.node.name] = x.node.publicURL)
    const sliderSettings = {
      infinite: true,
      swipeToSlide: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          }
        },
      ]
    }
    return (
      <div className={style.innerBlock}>
          <h2
            dangerouslySetInnerHTML={{
              __html: config.heading,
            }}
          />
          <div className={style.opportunitiesContainer}>
          <Slider {...sliderSettings}>
            {config.blocks.map((el, index) => {
              return (
                <div key={index} className={style.opportunitiesContent}>
                  <img
                    className={style.opportunitiesImgContent}
                    src={pictures[el.imgName] || ''}
                    alt={el.imgName}
                  />
                  {el.infoText && 
                    <button className={style.opportunitiesInfoButton} onClick={this.toggleInfo}>
                      <img src={pictures['info-btn']} className={style.opportunitiesImg} />
                  </button>
                  }
                  {this.state.activeInfo && el.infoText &&(
                    <div className={style.opportunitiesInfoText}>{el.infoText}</div>
                  )}
                  <div className={style.opportunitiesTitle}>{el.title}</div>
                </div>
              )
            })}
          </Slider>
            {/* <button className={style.opportunitiesButton} onClick={this.prefBlock}>
              <img src={pictures['vector-prev']} alt="Назад" />
            </button>
            <div className={style.opportunitiesContent}>
              <img
                className={style.opportunitiesImgContent}
                src={pictures[config.blocks[this.state.activeBlock].imgName] || ''}
                alt={config.blocks[this.state.activeBlock].imgName}
              />
              {config.blocks[this.state.activeBlock].infoText && 
                <button className={style.opportunitiesInfoButton} onClick={this.toggleInfo}>
                i
                <img src={pictures['info-btn']} className={style.opportunitiesImg} />
              </button>
              }
              {this.state.activeInfo && config.blocks[this.state.activeBlock].infoText &&(
                <div className={style.opportunitiesInfoText}>{config.blocks[this.state.activeBlock].infoText}</div>
              )}
              <div className={style.opportunitiesTitle}>{config.blocks[this.state.activeBlock].title}</div>
            </div>
            <button className={style.opportunitiesButton} onClick={this.nextBlock}>
              <img src={pictures['vector']} alt="Вперед" />
            </button> */}
          </div>
        </div>
    )
  }
}

export default injectIntl(OpportunitiesBlock)
