import React from 'react'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from './FaceRadarComponents.module.scss'
import DescriptionBlock from './components/DescriptionBlock'
import AreasUseBlock from './components/AreasUseBlock'
import OpportunitiesBlock from './components/OpportunitiesBlock'
import HowWorksBlock from './components/HowWorksBlock'
import FeedbackBlock from './components/FeedbackBlock'


class FaceRadarComponents extends React.Component {
  state = { intl: this.props.intl.messages.faceRadarPage }

  static propTypes = {
    images: PropTypes.array,
    intl: PropTypes.object,
  }

  render() {
    const { intl } = this.state
    return (
      <React.Fragment>
        <div className={classnames(style.basicBlock)}>
          <h1 dangerouslySetInnerHTML={{ __html: intl.heading }} />
          <DescriptionBlock config={intl.descriptionBlock} />
          <AreasUseBlock config={intl.areasUseBlock} images={this.props.images}/>
          <OpportunitiesBlock config={intl.opportunitiesBlock} images={this.props.images} />
          <HowWorksBlock config={intl.howWorksBlock} images={this.props.images} />
          <FeedbackBlock config={intl.feedbackBlock} />
        </div>
      </React.Fragment>
    )
  }
}

export default injectIntl(FaceRadarComponents)
