import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../FaceRadarComponents.module.scss'

class DescriptionBlock extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
  }
  

  render() {
    const { config } = this.props
    return (
      <div className={style.innerBlock}>
        <h2
          dangerouslySetInnerHTML={{
            __html: config.heading,
          }}
        />
        <p
          className={style.descriptionText}
          dangerouslySetInnerHTML={{
            __html: config.text,
          }}
        />
        <div className={style.descriptionTextBlock}>
          <p
            dangerouslySetInnerHTML={{
              __html: config.textBlock,
            }}
          />
        </div>
        <div className={style.descriptionButtonBlock}>
          <a className={style.descriptionLink} href='#feedbackblock'>{config.btnText}</a>
        </div>
      </div>
    )
  }
}

export default injectIntl(DescriptionBlock)
