import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../FaceRadarComponents.module.scss'

class AreasUseBlock extends PureComponent {

  state = {
    activeBlock: -1,
    hiddenBlock: -1,
    isDesktop: true,
  }

  static propTypes = {
    config: PropTypes.object,
    images: PropTypes.array,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (window?.innerWidth < 769) {
        this.setState(() => ({ isDesktop: false }));
      }
    }
  }

  setActive = i => {
    if (i === this.state.activeBlock) {
      this.setState(() => {
        return {
          activeBlock: -1,
          hiddenBlock: -1,
        }
      })
    } else {
      let delta = 2
      if (i + 2 < this.props.config.blocks.length) {
        delta = 2
      } else {
        delta = -2
      }
      this.setState(() => {
        return {
          activeBlock: i,
          hiddenBlock: i + delta,
        }
      })
    }
  }
  
  render() {
    const { config, images } = this.props
    let pictures = {};
    images.map(x => pictures[x.node.name] = x.node.publicURL)
    return (
      <div className={style.innerBlock}>
        <h2
          dangerouslySetInnerHTML={{
            __html: config.heading,
          }}
        />
        <div className={style.areasUseContainer}>
          <div className={style.areasUseColm}>
            {config.blocks?.map((item, i) => {
              if (i % 2 === 0 && i !== this.state.hiddenBlock) {
                return (
                  <div
                    key={i}
                    className={
                      this.state.activeBlock === i
                        ? style.areasUseBlockActive
                        : this.state.isDesktop
                        ? style.areasUseBlock
                        : style.areasUseBlockActive
                    }
                    onClick={() => (this.state.isDesktop ? this.setActive(i) : null)}
                  >
                    <div className={style.areasUseBlockHeader}>
                      <img className={style.areasUseBlockImg} src={pictures[item.imgName] || ''} alt={item.imgName} />
                      <p className={style.areasUseTitle}>{item.title}</p>
                    </div>

                    {this.state.isDesktop ? (
                      this.state.activeBlock > -1 &&
                      this.state.activeBlock === i && (
                        <ul className={style.areasUseUlBlock}>
                          {config.blocks[this.state.activeBlock]?.ulData.map((item, i) => {
                            return (
                              <li key={i} className={style.areasUseLi}>
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      )
                    ) : (
                      <ul className={style.areasUseUlBlock}>
                        {config.blocks[i]?.ulData.map((item, i) => {
                          return (
                            <li key={i} className={style.areasUseLi}>
                              {item}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                )
              }
            })}
          </div>

          <div className={style.areasUseColm}>
            {config.blocks?.map((item, i) => {
              if (i % 2 === 1 && i !== this.state.hiddenBlock) {
                return (
                  <div
                    key={i}
                    className={
                      this.state.activeBlock === i
                        ? style.areasUseBlockActive
                        : this.state.isDesktop
                        ? style.areasUseBlock
                        : style.areasUseBlockActive
                    }
                    onClick={() => (this.state.isDesktop ? this.setActive(i) : null)}
                  >
                    <div className={style.areasUseBlockHeader}>
                      <img className={style.areasUseBlockImg} src={pictures[item.imgName] || ''} alt={item.imgName} />
                      <p className={style.areasUseTitle}>{item.title}</p>
                    </div>

                    {this.state.isDesktop ? (
                      this.state.activeBlock > -1 &&
                      this.state.activeBlock === i && (
                        <ul className={style.areasUseUlBlock}>
                          {config.blocks[this.state.activeBlock]?.ulData.map((item, i) => {
                            return (
                              <li key={i} className={style.areasUseLi}>
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      )
                    ) : (
                      <ul className={style.areasUseUlBlock}>
                        {config.blocks[i]?.ulData.map((item, i) => {
                          return (
                            <li key={i} className={style.areasUseLi}>
                              {item}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(AreasUseBlock)
