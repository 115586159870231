import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../FaceRadarComponents.module.scss'

class HowWorksBlock extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
    images: PropTypes.array,
  }
  

  render() {
    const { config, images } = this.props
    let pictures = {};
    images.map(x => pictures[x.node.name] = x.node.publicURL)
    return (
      <div className={style.innerBlock}>
        <h2
          dangerouslySetInnerHTML={{
            __html: config.heading,
          }}
        />
        {/* <div className={style.howWorksContent}>
          <div className={style.howWorksGif}>
            <img src={pictures[config.gifName] || ''} alt={config.gifName} />
          </div>
        </div> */}
        <div className={style.howWorksContent}>
          {config.blocks.map((item, i) => {
            return (
              <div key={i} className={style.howWorksContentItem}>
                <div className={style.howWorksTextBlock}>
                  <div className={style.howWorksTitle}>{item.title}</div>
                  <p className={style.howWorksText}>{item.text}</p>
                </div>
                <img className={style.howWorksGif} src={pictures[item.imgName] || ''} alt={item.imgName} />
              </div>
            )
          })}
        </div>
        <div className={style.howWorksEndText}>{config.bottomText}</div>
      </div>
    )
  }
}

export default injectIntl(HowWorksBlock)
